<template>
  <div>
    <div class="top-change" @click="goBack">
      <div class="l-where">
        系统设置<span>/</span>合同标签
      </div>
      <div class="r-back" @click="goBack">
        <i class="iconfont icon-fanhui"></i>
      </div>
    </div>
    <div class="b-table">
      <div style="display: flex;margin-bottom: 20px">
        <div style="display: flex;align-items: center;margin-right: 20px">
          <Input v-model="storePrams.title" style="width: 300px" enter-button placeholder="请输入合同标签名称"/>
        </div>
        <div>
          <Button type="primary" @click="searchList">查询</Button>
        </div>
      </div>
      <Button type="primary" @click="creatTalbe" style="margin-bottom:16px" >创建</Button>
      <!-- <div class="creat-table" @click="creatTalbe">创建</div> -->
      <Table :columns="platformColumns" :data="storeList" :loading='loadingTab' >
        <template slot-scope="{ index }" slot="idx">
          <div>{{index + 1}}</div>
        </template>
        <template slot-scope="{ row  }" slot="ticketName">
          <div>
            <Tag :color="row.color_value ? row.color_value : 'default' ">{{ row.name }}</Tag>
          </div>
        </template>
        <template slot-scope="{ row }" slot="operation">
          <div class="flex-c">
            <div class="table-btn edit" @click="editIt(row)">编辑</div>
            <div class="table-btn danger" @click="clearDel(row.id)">删除</div>
          </div>
        </template>
      </Table>
      <br/>
      <Page :total="storePrams.total"
            :current="storePrams.page"
            style="text-align:right"
            show-elevator show-total
            @on-change="pageChange"
            :page-size="storePrams.limit"/>
    </div>
    <Modal
        :title="tableTitle"
        v-model="showMsg"
        width="600"
        @on-ok="submit"
        :mask-closable="false">
      <Form ref="formItem" :model="platform" :label-width="100" style="width: 500px;margin-top: 20px">
        <FormItem label="标签名称">
          <Input v-model="platform.name" placeholder="请输入经办人姓名"></Input>
        </FormItem>
        <FormItem label="标签颜色">
          <div class="flex">
            <Select v-model="platform.color_value" style="width: 300px" @on-change="selectColor">
              <Option v-for="item in cityList" :value="item.value" :key="item.value">{{ item.label }}</Option>
            </Select>
            <div class="color-item" :style="'background:' + useColor "></div>
          </div>
        </FormItem>
      </Form>
    </Modal>
  </div>
</template>

<script>
import {addLabel, editLabel, labelList , delLable} from '../../../api/interFace'
export default {
  name: "index",
  data() {
    return {
      platformColumns: [
        {
          title: '#',
          slot: 'idx',
          width: 80,
          align: 'center'
        },
        {
          title: '标签名称',
          slot: 'ticketName',
          align: 'center'
        },
        {
          title: '创建时间',
          key: 'created_at',
          align: 'center'
        },
        {
          title: '操作',
          key: 'action',
          width: 210,
          fixed: 'right',
          align: 'center',
          slot: 'operation'
        }
      ],
      storeList: [],
      loadingTab : false,
      showMsg: false,
      tableTitle:'新建合同标签',
      storePrams: {
        limit: 10,
        page: 1,
        total: 0,
        title:''
      },
      platform:{
        name:'',
        color_value:''
      },
      haveId:'',
      cityList: [
        {
          value: 'default',
          label: 'default'
        },
        {
          value: 'primary',
          label: 'primary'
        },
        {
          value: 'success',
          label: 'success'
        },
        {
          value: 'error',
          label: 'error'
        },
        {
          value: 'warning',
          label: 'warning'
        },
        {
          value: 'magenta',
          label: 'magenta'
        },
        {
          value: 'red',
          label: 'red'
        },
        {
          value: 'volcano',
          label: 'volcano'
        },
        {
          value: 'orange',
          label: 'orange'
        },
        {
          value: 'gold',
          label: 'gold'
        },
        {
          value: 'yellow',
          label: 'yellow'
        }
      ],
      useColor:'#FD5454'
    }
  },
  created() {
    this.$store.commit('changePollingSpin', false)
    this.getList()
  },
  methods: {
    searchList(){
      this.storePrams.page = 1
      this.getList()
    },
    getList() {
      this.loadingTab = true
      labelList(this.storePrams).then(res => {
        this.loadingTab = false
        this.storeList = res.data.data || []
        this.storePrams.total = res.data.total
      }).catch(res => {
        this.loadingTab = false
        console.log(res)
      })
    },
    selectColor(e){
      let day
      switch (e) {
        case 'default':
          day = "#F7F7F7";
          break;
        case 'primary':
          day = "#2D8CF0";
          break;
        case 'success':
          day = "#19BE6B";
          break;
        case 'error':
          day = "#ED4014";
          break;
        case 'warning':
          day = "#FF9900";
          break;
        case 'magenta':
          day = "#EB3197";
          break;
        case 'volcano':
          day = "#FA5E28";
          break;
        case 'orange':
          day = "#FA8C16";
          break;
        case 'gold':
          day = "#FAAD14";
          break;
        case 'yellow':
          day = "#FADB14";
          break;
        case 'red':
          day = "#F5222D";
      }
      this.useColor = day
    },
    editIt(row){
      this.showMsg = true
      this.platform.name = row.name
      this.haveId = row.id
    },
    creatTalbe(){
      this.showMsg = true
      this.haveId = ''
      this.reset()
    },
    goBack(){
      this.$emit('goBack')
    },
    submit(){
      if(this.haveId){
        this.tableTitle = '修改合同标签'
        editLabel({id:this.haveId,name:this.platform.name}).then(res=>{
          this.$Message.success(res.msg)
          this.getList()
        }).catch(res=>{
          this.$Message.error(res.msg)
        })
      }else {
        this.tableTitle = '新增合同标签'
        addLabel(this.platform).then(res=>{
          this.$Message.success(res.msg)
          this.getList()
        }).catch(res=>{
          this.$Message.error(res.msg)
        })
      }
    },
    clearDel(id) {
      this.$Modal.confirm({
        title: '删除提示',
        content: '请问您是否确认删除此条合同标签，删除后无法恢复!!!',
        onOk: () => {
          delLable({id:id}).then(res=>{
            this.$Message.success(res.msg)
            this.getList()
          }).catch(res=>{
            this.$Message.error(res.msg)
          })
        },
        onCancel: () => {
        }
      });
    },
    // agree(id){
    //   this.showMsg = true
    //   this.tableTitle='新建签署者'
    //   getInfo({id:id}).then(res=>{
    //     this.platform = res.data
    //     this.platform.type = String(res.data.type)
    //     if(res.data.requirement === 1 ) {
    //       this.plus = [1]
    //     }else if(res.data.requirement === 2 ) {
    //       this.plus = [2]
    //     }else if(res.data.requirement === 3){
    //       this.plus = [1, 2]
    //     }else if(res.data.requirement === 4 ) {
    //       this.plus = [4]
    //     }else if(res.data.requirement === 5 ) {
    //       this.plus = [1, 4]
    //     }else if(res.data.requirement === 6 ) {
    //       this.plus = [2, 4]
    //     }else if(res.data.requirement === 7 ) {
    //       this.plus = [1, 2, 4]
    //     }
    //   }).catch(res=>{
    //     this.$Message.error(res.msg)
    //   })
    // },
    pageChange(index) {
      this.storePrams.page = index
      this.getList()
    },
    reset(){
      this.platform = {
        name: '',
        color_value:''
      }
    }
  }
}
</script>

<style scoped lang="scss">
.top-change {
  width: 100%;
  cursor: pointer;
  background: #FFFFFF;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 30px;
  box-sizing: border-box;
  height: 60px;
  .l-where {
    //position: absolute;
    //left: 30px;
    //top: 50%;
    //transform: translateY(-50%);
    font-size: 18px;
    font-weight: bold;
    color: #1B2742;
    span {
      color: #4877E8;
    }
  }
  .r-back {
    width: 200px;
    cursor: pointer;
    text-align: end;
    .iconfont {
      //position: absolute;
      //right: 30px;
      //top: 50%;
      //transform: translateY(-50%);
      font-size: 26px;
      cursor: pointer;

      &:hover {
        color: #4877E8;
      }
    }
  }
}
.top-wrap {
  background: white;
  padding: 30px;
  box-sizing: border-box;
  margin-top: 20px;
  border-radius: 4px;
  box-shadow: 2px 5px 2px -2px #e7e5e0;
}

.input-wrap {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.b-table {
  margin-top: 20px;
  min-height: 700px;
  background: white;
  padding: 30px;
  box-sizing: border-box;
}

.wrap-label {
  font-size: 14px;
  margin-right: 12px;
}
.l-pic,.r-pic {
  width: 150px;
  height: 150px;
}
img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.creat-table {
  font-size: 12px;
  padding: 5px 12px;
  border: 1px solid #1a9aef;
  color: #1a9aef;
  border-radius: 6px;
  width: fit-content;
  cursor: pointer;
  margin-bottom: 20px;
}
.color-item {
  width: 25px;
  height: 25px;
  margin-left: 12px;
  border-radius: 2px;
}
</style>

