<template>
    <div>
        <div v-if="needMe">
            <div class="top-change">
                <div class="l-where">
                    系统设置<span>/</span>印章审批
                </div>
                <div class="r-back" @click="goBack">
                    <i class="iconfont icon-fanhui"></i>
                </div>
            </div>
            <div class="page-cont">
                <div class="a-table" style="margin-top:20px">
                    <div class="flex" style="padding-top:32px;margin-left:30px">
                        <div class="nav-title">公章开关: </div>
                        <div style="margin-left:8px">
                            <i-switch  :before-change="handleBeforeChange" v-model="seal_switch" :true-value="1" :false-value="0" size="large">
                                <span slot="open">开启</span>
                                <span slot="close">关闭</span>
                            </i-switch>  
                        </div>
                                         
                    </div>
                </div>
                <div v-if='seal_switch == 1'>
                    <div class="a-table" >
                        <Select v-model="storePrams.status" default-label="审核状态" style="width:200px;margin-left: 12px;margin-top:20px;padding-left:20px"  @on-change='getListSearch' >
                            <Option v-for="item in selectList" :value="item.value" :key="item.value">{{ item.label }}</Option>
                        </Select>
                    </div>
                    <div class="b-table" >
                        <Table :columns="platformColumns" :data="storeList" :loading='loadingTab'>
                            <template slot-scope="{ row }" slot="operation">
                                <div class="flex" style="align-items: center;justify-content: center;">
                                    <div v-if="storePrams.status === '0'" class="table-btn blue" @click="agree(row)">同意</div>
                                    <div v-if="storePrams.status === '0'" class="table-btn danger" @click="clearDel(row.id)">拒绝</div>
                                    <Button v-if="storePrams.status === '1'&&row.status === 1" type="error" size='small' @click="goDel(row)">删除</Button>
                                    <!-- <div  class="table-btn danger" @click="goDel(row)">删除</div> -->
                                </div>
                            </template>
                            <template slot-scope="{ row }" slot="cont">
                                <div v-if="row.contract_arr != null " class="cont-cla" @click="goSeeCont(row)"  >{{row.contract_arr.contract_name}}</div>
                            </template>
                            <template slot-scope="{ row }" slot="status">
                                <div>{{row.status === 0 ? '待审核' : row.status === 1 ? '审核成功' : row.status === 2 ?  '审核失败' : ''}}</div>
                            </template>
                            <template slot-scope="{ row }" slot="account">          
                                <div @click="openImg(row.seal_id.seal_img)" v-if="row.seal_id.seal_content" style="cursor:pointer;" >{{row.seal_id.seal_content}}</div>
                                <img @click="openImg(row.seal_id.seal_img)" :src="row.seal_id.seal_img" v-else style="cursor:pointer;width:50px;height:50px" >
                                <!-- <div>{{row.seal_id ? row.seal_id.seal_type_name : ''}}</div> -->
                            </template>
                            <template slot-scope="{ row }" slot="real_name">
                                <div>{{row.user_id.real_name}}</div>
                            </template>
                            <template slot-scope="{ row }" slot="admin">
                                <div>{{row.user_id.account}}</div>
                            </template>
                        </Table>
                        <br/>
                        <Page :total="storePrams.total"
                            :current="storePrams.page"
                            style="text-align:right"
                            show-elevator show-total
                            @on-change="pageChange"
                            :page-size="storePrams.limit"/>
                    </div>  
                </div>  
            </div>        
        </div>
        <Allcontracts @titleSync="goToView" ref="contracts" ></Allcontracts>
        <Modal
            title="拒绝申请"
            v-model="showMsg"
            @on-ok="requireMsg"
            :mask-closable="false">
            <div class="input-wrap">
                <div class="wrap-label">拒绝原因:</div>
                <div class="wrap-inp">
                    <Input v-model="refuse_reason" type="textarea" :rows="4" placeholder="请输入拒绝原因" style="width: 400px" />
                </div>
            </div>
        </Modal>
        <Modal
            title="同意申请"
            v-model="showMore"
            @on-ok="requireMore"
            :mask-closable="false">
            <div style="display: flex;align-items: center;margin-top: 40px;margin-bottom: 40px">
                <div class="wrap-label">短信提醒</div>
                <div style="margin-left: 16px;">
                    <i-switch size="large" v-model="is_sms" :value="is_sms" :true-value="1" :false-value="0">
                        <span slot="open">开启</span>
                        <span slot="close">关闭</span>
                    </i-switch>
                </div>
            </div>
        </Modal>

    </div>
</template>

<script>
import {examineList,examineSeal,del_applyrecord,get_sealstatus,apply_switch } from '../../../api/interFace'
import Allcontracts from './allcontractss'
export default {
    name: "index",
    components: {
        Allcontracts
    },
    data(){
        return{
            selectList:[
                {value:'0',label:"待审核"},
                {value:'1',label:"审核完成"},
            ],
            
            seal_switch: 0 , //印章权限开关
            platformColumns: [
                {
                    title: '#',
                    key: 'id',
                    width: 80,
                    align:'center'
                },
                {
                    title: '用户姓名',
                    slot: 'real_name',
                    align:'center'
                },
                {
                    title: '申请账户',
                    slot: 'admin',
                    align:'center'
                },
                {
                    title: '印章名称',
                    slot: 'account',
                    align:'center'
                },
                {
                    title: '合同名称',
                    key: 'contract_id',
                    slot: 'cont',
                    align:'center'
                },
                {
                    title: '使用次数',
                    key: 'seal_num',
                    align:'center',
                    width: 120,
                },
                {
                    title: '申请原因',
                    key: 'apply_reason',
                    align:'center'
                },
                {
                    title: '申请状态',
                    key: 'status',
                    slot: 'status',
                    align:'center'
                },
                {
                    title: '拒绝原因',
                    key: 'refuse_reason',
                    align:'center'
                },
                {
                    title: '申请时间',
                    key: 'created_at',
                    width: 200,
                    align:'center'
                },
                {
                    title: '操作',
                    key: 'action',
                    width: 200,
                    fixed: 'right',
                    align: 'center',
                    slot: 'operation'
                }
            ],
            needMe:true,      
            storeList:[],
            loadingTab:false,
            showMsg:false,
            showMore:false,
            storePrams: {
                limit: 10,
                page: 1,
                total: 0,
                status:'0'
            },
            refuse_reason:'',
            needId:'',
            is_sms:1
        }
    },
    created() {
        this.$store.commit('changePollingSpin', true)
        this.getList()
        this.get_sealstatus()
    },
    methods:{        
        openImg(e){
            this.$Modal.info({
                title: '预览图片',
                content: '<img style="width:250px;height:250px" src="'+e+'" />'
            });
        },
        get_sealstatus(){
            this.$store.commit('changePollingSpin', true)
            get_sealstatus().then(res=>{
                this.seal_switch = res.data.seal_switch
                this.$store.commit('changePollingSpin', false)
            })
        },
        getList(){
            this.loadingTab = true
            examineList(this.storePrams).then(res=>{
                this.loadingTab = false
                this.storeList = res.data.data || []
                this.storePrams.total = res.data.total
            })
        },
        getListSearch(e){
            console.log(e)
            this.storePrams.page = 1
            this.getList()
        },
        handleBeforeChange () {
            return new Promise((resolve) => {
                this.$Modal.confirm({
                    title: '切换确认',
                    content: '您确认要切换开关状态吗？',
                    onOk: () => {
                        
                        apply_switch({status:this.seal_switch == 0 ? 1 : 0}).then(res=>{
                            this.$Message.success(res.msg)
                            this.get_sealstatus()
                        }).catch(res=>{
                            this.$Message.error(res.msg)
                        })
                        resolve();
                    }
                });
            });
        },
        goSeeCont(e){
            console.log(e)
            this.$nextTick(()=>{
                this.needMe = false
                this.$refs.contracts.openIt(e.contract_arr)
            })
        },
        goToView(){
            this.needMe = true
        },
        goBack(){
            this.$emit('goBack')
        },
        checkIt(e){
            this.storePrams.status = e
            this.getList()
        },
        goDel(e){
            console.log(e)
            this.$Modal.confirm({
                title: '删除提示',
                content: '请问您是否确认删除此条印章申请吗，删除后无法恢复!!!',
                onOk: () => {
                    del_applyrecord({id:e.id}).then(res=>{
                        this.$Message.success(res.msg)
                        this.getList()
                    }).catch(res=>{
                        this.$Message.error(res.msg)
                    })
                },
                onCancel: () => {
                }
            });
        },
        requireMsg(){
            examineSeal({id:this.msgID,status:'2',refuse_reason:this.refuse_reason}).then(res=>{
                this.$Message.success(res.msg)
                this.getList()
            }).catch(res => {
                this.$Message.error(res.msg)
            })
        },
        agree(e){
            this.showMore = true
            this.needId = e.id
        },
        requireMore(){
            this.$Modal.confirm({
                title: '操作提示',
                content: '请问您是否确认此用户使用该印章，确认无误后请点击确定按钮。',
                onOk: () => {
                    examineSeal({id:this.needId,status:'1',is_sms:this.is_sms}).then(res=>{
                        this.$Message.success(res.msg)
                        this.getList()
                    }).catch(res => {
                        this.$Message.error(res.msg)
                    })
                },
                onCancel: () => {
                }
            });
        },
        clearDel(id){
            this.msgID = id
            this.showMsg = true
        },
        pageChange(index) {
            this.storePrams.page = index
            this.getList()
        },
    }
}
</script>

<style scoped lang="scss">
.cont-cla{
    color: #1A73E8;
    cursor: pointer;
}
.top-change {
    width: 100%;
    background: #FFFFFF;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 30px;
    box-sizing: border-box;
    height: 60px;
    .l-where {
        //position: absolute;
        //left: 30px;
        //top: 50%;
        //transform: translateY(-50%);
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #1B2742;
        span {
            color: #4877E8;
        }
    }
    .r-back {
        width: 200px;
            cursor: pointer;
            text-align: end;
        .iconfont {
            //position: absolute;
            //right: 30px;
            //top: 50%;
            //transform: translateY(-50%);
            font-size: 26px;
            cursor: pointer;
            &:hover {
            color: #4877E8;
            }
        }
    }
}
.top-wrap {
    background: white;
    padding: 30px;
    box-sizing: border-box;
    margin-top: 20px;
    border-radius: 4px;
    box-shadow:2px 5px 2px -2px  #e7e5e0;
}
.input-wrap {
    display: flex;
    align-items: center;
    margin-top: 20px;
}
.b-table {
    // margin-top: 20px;
    background: white;
    padding: 30px;
    box-sizing: border-box;
}
.a-table {
    // margin-top: 20px;
    background: white;
}
.wrap-label {
    font-size: 14px;
    margin-right: 12px;
}
.page-cont {
    background: white;
    min-height: 700px;
}
</style>
<style>
.top-wrap .ivu-tabs-nav .ivu-tabs-tab {
    font-size: 18px!important;
}
</style>
