export function comCreateSeal(_opts) {
    let opts = {
        canvas: null, // 生成的canvas dom
        company: "", // 机构名称
        companyTight: false, // 公司名称是否紧凑
        name: "专用章"
    }
    if (typeof _opts == 'object') {
        opts = Object.assign(opts, _opts);
    }
    let context = opts.canvas.getContext('2d');

    context.fillStyle = 'rgba(255, 255, 255, 0)';

    // 绘制印章边框
    let [width, height, r] = [151, 151, 145];
    // var width = opts.canvas.width / 2;
    // var height = opts.canvas.height / 2;
    context.lineWidth = 6;
    context.strokeStyle = "#f00";
    context.beginPath();
    context.arc(width, height, r, 0, Math.PI * 2);
    context.stroke();
    context.save();

    //画五角星
    if(opts.company.length>4){
        context.save();
        context.fillStyle = "#f00";
        context.translate(width, height);//移动坐标原点
        context.rotate(Math.PI + 0);//旋转
        context.beginPath();//创建路径
        let x = Math.sin(0);
        let y = Math.cos(0);
        let dig = Math.PI / 5 * 4;
        for (let i = 0; i < 5; i++) {//画五角星的五条边
            x = Math.sin(i * dig);
            y = Math.cos(i * dig);
            context.lineTo(x * 50, y * 50);
        }
        context.closePath();
        context.stroke();
        context.fill();
        context.restore();
    }
    

    // 绘制印章名称
    context.font = '22px Helvetica';
    context.textBaseline = 'middle';//设置文本的垂直对齐方式
    context.textAlign = 'center'; //设置文本的水平对对齐方式
    context.lineWidth = 1;
    context.fillStyle = '#f00';
    context.fillText(opts.name, width, height + 73);

    // 绘制印章单位
    context.translate(width, height);// 平移到此位置,
    let count = opts.company.length;// 字数
    let maxCount = 0
    let angle = 0
    if (count > 14) {
         maxCount = 18;
        context.font = '28px Helvetica'
        if (opts.companyTight) {
            angle = 4 * Math.PI / 51;// 字间角度
        } else {
             angle = 4 * Math.PI / (3 * (count - 1));// 字间角度
        }
    } else {
         maxCount = 14;
        context.font = '36px Helvetica'
        if (opts.companyTight) {
             angle = 4 * Math.PI / 39;// 字间角度
        } else {
             angle = 4 * Math.PI / (3 * (count - 1));// 字间角度
        }
    }
    let chars = opts.company.split("");
    let c;

    for (let i = 0; i < count; i++) {
        c = chars[i];// 需要绘制的字符
        if (i == 0) {
            if (opts.companyTight) {
                context.rotate(5 * Math.PI / 6 + angle * ((maxCount - count) / 2));
            } else {
                context.rotate(5 * Math.PI / 6);
            }
        } else {
            context.rotate(angle);
        }
        context.save();
        context.translate(r-25, 0);// 平移到此位置,此时字和x轴垂直
        context.rotate(Math.PI / 2);// 旋转90度,让字平行于x轴
        context.fillText(c, 0, 5);// 此点为字的中心点
        context.restore();
    }


    // context.translate(width, height);// 平移到此位置,

    let number_count = opts.number.length;
    let number_chars = opts.number.split("");

    let number_angle = 1.16*Math.PI / (3 * (number_count - 1));// 字间角度
    context.font = '18px Helvetica'
    for (let i = 0; i < number_count; i++) {
        c = number_chars[i];// 需要绘制的字符
        if (i == 0) {
            context.rotate( Math.PI/1.9);
        } else {
            context.rotate(-number_angle);
        }
        context.save();
        context.translate(r-20, 0);// 平移到此位置,此时字和x轴垂直
        context.rotate(Math.PI*1.5);// 旋转90度,让字平行于x轴
        context.fillText(c, 0, 3);// 此点为字的中心点
        context.restore();
    }


    // comCreateSeal({canvas:document.getElementById("my_canvas"), company:'安徽互联造物科技有限公司安徽互联造物科技有限公司', name:'测试专用章', number:'98765432115', companyTight:false});
}

