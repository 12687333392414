<template>
    <div>
        <!-- <div class="new-hearder" v-if="showSecond">    
            <div class="r-back" @click="backOut" >
                <i class="iconfont icon-fanhui"></i>
                <div>返回</div>
            </div>
        </div> -->

        <div class="dabox" v-if="showSecond">                            
            <div class="leftbox">      
                <!-- <div class="new-left">
                    <div class="left-tittle">管理记录</div>
                    <div v-if='editRecordList.length == 0' style="margin-top:24px"> 暂无管理记录~</div>
                    <div v-for='(item,index) in editRecordList' :key="index" class="new-record">
                        <div class="right-record">
                            <div class="landian"></div>
                            <div class="cont">
                                <h3 v-if='item.type == 0'>发起合同</h3> 
                                <h4 v-if='item.type == 1'>签署合同</h4> 
                                <h4 v-if='item.type == 2'>作废合同</h4> 
                                <span>{{item.describe}}</span>
                            </div>
                            
                           
                        </div>
                        <div class="record-time">{{item.created_at}}</div>
                    </div>
                </div>           -->
                <!-- <Card  class="buttonCss" dis-hover :bordered="false">
                    <Button @click="download" class="operationBtn">下载</Button>
                </Card> -->
                <Card class="buttonCss" dis-hover :bordered="false">
                    <Button class="operationBtn" @click="backOut">返回</Button>
                </Card>
            </div>
            <div class="right-box">
                <div v-if="img_list.length>0">
                    <div v-for="item in img_list" class="bg-img" :key="item">
                        <img :src="item">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
var that;
import {getpdf,get_contractloglist} from '../../../api/interFace'

export default {
  name: 'allcontracts',
  props: {
    titleSync: Object
  },
  data() {
    return {
      data: {},
      list: [],
      editRecordList:[],
      template_html_content: "",
      img_list: [],
      timer:'48',
      needCheck:true,
      downPath:'',
      showSecond:false
    }
  },
  created() {
    that = this;
    // this.data = this.titleSync.arr;
    // this.template_html_content = this.titleSync.arr.contract_content
    // this.img_list = this.titleSync.arr.contract_imgs
    // if(this.img_list.length>0){
    //   this.resetWidth();
    // }else {
    //   this.resetWidthElse();
    // }
    this.timer=Date.parse(new Date());
  },
  methods: {
    backOut() {
      this.showSecond = false
      this.$emit('titleSync', false)
    },
    openIt(e){
      this.showSecond = true
      this.img_list = e.contract_imgs || []
      this.downPath = e.contract_path
      this.needId = e.id
      this.get_contractloglist()
    },
    resetWidth() {
      this.titleSync.arr.participant.forEach(function (v, k, arr) {
        if(v.position_pagenum > 0){
          v.positionY = Number(v.positionY) + ((v.position_pagenum - 1) * 1147)
        }
        if(v.positionfirm_pagenum > 0){
          v.positionfirmY = Number(v.positionfirmY) + ((v.positionfirm_pagenum - 1) * 1147)
        }
        if (arr[k].yinzhang != null && arr[k].yinzhang != "") {
          let img = new Image();
          img.src = arr[k].yinzhang;
          if (img.complete) {
            arr[k].yinzhang_width = img.width / 3;
            arr[k].yinzhang_height = img.height / 3;
          } else {
            // 加载完成执行
            img.onload = function () {
              arr[k].yinzhang_width = img.width / 3;
              arr[k].yinzhang_height = img.height / 3;
            };
          }
        }
      })
      setInterval(function () {
        that.list = that.titleSync.arr.participant;
      }, 100)
    },
    resetWidthElse(){
      this.titleSync.arr.participant.forEach(function (v, k, arr) {
        if (arr[k].yinzhang != null && arr[k].yinzhang != "") {
          let img = new Image();
          img.src = arr[k].yinzhang;
          if (img.complete) {
            arr[k].yinzhang_width = img.width / 3;
            arr[k].yinzhang_height = img.height / 3;
          } else {
            // 加载完成执行
            img.onload = function () {
              arr[k].yinzhang_width = img.width / 3;
              arr[k].yinzhang_height = img.height / 3;
            };
          }
        }
      })
      setInterval(function () {
        that.list = that.titleSync.arr.participant;
      }, 100)
    },
    get_contractloglist() {
      get_contractloglist({contract_id:this.needId}).then(res=>{
        this.editRecordList = res.data
      }).catch(res=>{
        this.$Message.error(res.msg)
      })
    },
    download() {
      if(!that.needCheck){
        return that.$Message.error('请勿重复下载')
      }
      that.$Message.loading({
        content: '生成中...',
        duration: 0
      })
      if(this.downPath) {
        that.$Message.destroy()
        window.open(this.downPath)
        that.needCheck=true
        return
      }
      that.needCheck=false
      getpdf({id:this.needId}).then(res=>{
        that.$Message.destroy()
        window.open(res.data)
        that.needCheck=true
      }).catch(res=>{
        console.log(res)
        that.needCheck=true
      })
    }
  }
}

</script>

<style scoped lang="scss">
.r-back {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 18px;
    margin-left:16px;
    .iconfont {
        font-size: 24px;    
        margin-right: 8px;    
    }
    &:hover {
        color: #4877E8;
    }
}
.new-left {
    padding-top: 48px;
    .left-tittle {
        font-size: 24px;
        text-align: start;
        margin-left: 24px;
    }
    .new-record {
        .record-time {
            font-size: 14px;
            text-align: center;
        }
        .right-record {
            margin-top: 24px;
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            .cont {
                width: 140px;
                text-align: start;
                span {
                    word-break: break-all;
                    font-size: 12px;
                }
            }
            // span {
            //     width: 140px;
            //     text-align: start;
            // }
        }
        .landian {
            color: #008AFF;
            background: #008AFF;
            border-radius: 7px;
            width: 7px;
            height: 7px;
            margin-top:7px;
            margin-left: 24px ;
            margin-right: 12px ;
        }
    }
}
</style>

<style scoped>
.dabox {
  width: 100%;
  display: flex;
}
.new-hearder {
  background: #fff;
  width: 100%;
  height: 54px;
  border-radius: 8px;
  margin-bottom: 24px;
  padding: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;

}

.leftbox {
  width: 210px;
  background: white;  
  position: fixed;
  height: 100vh;
  overflow: auto;
  text-align: center;
  /* padding-top: 100px; */
}
.right-box {
  flex: 1;
}

.buttonCss {
  margin-top: 20px;
}

.ju {
  display: flex;
  align-items: center;
  justify-content: center;
}

.bg-img {
  width: 800px;
  margin: auto;
  background: white;
  margin-bottom: 2px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, .3);
}

.bg-img img {
  width: 100%;
  object-fit: cover;
  margin: auto;
  display: inline-block;
}
</style>
<style>
.new-more tr {
  height: 30px;

}

.new-more table {
  width: 100%;
  border: 1px solid #777777;
}

.new-more td, .new-more th {
  border: 1px solid #777777;
}

.MsoNormalTable {
  margin-left: unset !important;
}
</style>
